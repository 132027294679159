var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "wrap",
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.couponOptions,
            rules: _vm.rules,
            "label-width": "150px",
          },
        },
        [
          _c("topOperatingButton", {
            ref: "topOperatingButton",
            attrs: {
              showAudit: _vm.isShowAudit,
              isStopBtn: false,
              isSubmitAddBtn: false,
              isAuditBillBtn: false,
              isExamineBtn: true,
              disabled: _vm.disableOperate,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditForm,
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息", id: "basicInformation" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "活动编号",
                          },
                          model: {
                            value: _vm.couponOptions.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billNo", $$v)
                            },
                            expression: "couponOptions.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动名称", prop: "billName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: _vm.disableOperate,
                            placeholder: "活动名称",
                            maxlength: "40",
                          },
                          model: {
                            value: _vm.couponOptions.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billName", $$v)
                            },
                            expression: "couponOptions.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "活动日期", prop: "datetime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "mini",
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.disableOperate,
                          },
                          model: {
                            value: _vm.couponOptions.datetime,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "datetime", $$v)
                            },
                            expression: "couponOptions.datetime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案说明", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "方案说明长度介于 1 和 200 字符之间",
                            maxlength: "200",
                            autosize: { minRows: 1, maxRows: 1 },
                            disabled: _vm.disableOperate,
                          },
                          model: {
                            value: _vm.couponOptions.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.couponOptions, "billRemark", $$v)
                            },
                            expression: "couponOptions.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "活动优惠券", id: "basicInformation" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: " marT10 mar20" },
                  [
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { disabled: _vm.disableOperate, label: 1 },
                            on: { input: _vm.radioInpit },
                            model: {
                              value: _vm.couponOptions.caseFissionType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.couponOptions,
                                  "caseFissionType",
                                  $$v
                                )
                              },
                              expression: "couponOptions.caseFissionType",
                            },
                          },
                          [_vm._v("定额优惠券")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { disabled: _vm.disableOperate, label: 2 },
                            on: { input: _vm.radioInpit },
                            model: {
                              value: _vm.couponOptions.caseFissionType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.couponOptions,
                                  "caseFissionType",
                                  $$v
                                )
                              },
                              expression: "couponOptions.caseFissionType",
                            },
                          },
                          [_vm._v("凭手气优惠券")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "mb10",
                        attrs: { disabled: _vm.disableOperate, size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEvent("openCouponDialog")
                          },
                        },
                      },
                      [_vm._v("选择优惠券")]
                    ),
                    _c("EditTable", {
                      staticStyle: { width: "100%" },
                      attrs: { options: _vm.couponOptions },
                      on: { handleEvent: _vm.handleEvent },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "活动设置" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "giveRule" }, [
                  _c(
                    "div",
                    { staticClass: "multiplyTitle" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠券数量", required: true } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "", prop: "limitCouponQty" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "fillRuleinputMoney",
                                    attrs: {
                                      disabled: _vm.disableOperate,
                                      size: "mini",
                                      oninput:
                                        "if (value > 99999) value = 99999; value=value.replace(/^0|[^0-9]/g,'')",
                                      max: 9999999,
                                    },
                                    model: {
                                      value: _vm.couponOptions.limitCouponQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.couponOptions,
                                          "limitCouponQty",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "couponOptions.limitCouponQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.couponOptions.caseFissionType == 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "第N个领取金额最大",
                                required: true,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        size: "mini",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value: _vm.couponOptions.maxMoneyNth,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "maxMoneyNth",
                                            $$v
                                          )
                                        },
                                        expression: "couponOptions.maxMoneyNth",
                                      },
                                    },
                                    _vm._l(10, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: { value: item },
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        "margin-left": "10px",
                                      },
                                      attrs: {
                                        content:
                                          "指每个裂变券礼包所包含的优惠券数量。举例：数量设置为10，则每笔订单送出去的裂变券礼包里含有10张优惠券，最多可有10位用户分别领取一张。",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-question",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.couponOptions.caseFissionType == 2
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "金额最大优惠券",
                                required: true,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "RuleListdetail" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        disabled: _vm.disableOperate,
                                        size: "mini",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value:
                                          _vm.couponOptions
                                            .maxMoneyCouponCaseId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.couponOptions,
                                            "maxMoneyCouponCaseId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "couponOptions.maxMoneyCouponCaseId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.couponOptions.list,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.couponCaseName,
                                            value: item.couponCaseId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动可发起总量", required: true } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "", prop: "caseUsableTotal" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "fillRuleinputMoney",
                                    attrs: {
                                      disabled: _vm.disableOperate,
                                      size: "mini",
                                      oninput:
                                        "if (value > 99999) value = 99999; value=value.replace(/^0|[^0-9]/g,'')",
                                      max: 9999999,
                                    },
                                    model: {
                                      value: _vm.couponOptions.caseUsableTotal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.couponOptions,
                                          "caseUsableTotal",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "couponOptions.caseUsableTotal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取次数限制", required: true } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 0,
                                  },
                                  model: {
                                    value: _vm.couponOptions.drawTimesType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "drawTimesType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.drawTimesType",
                                  },
                                },
                                [_vm._v("不限制")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  disabled: _vm.disableOperate,
                                  label: 1,
                                },
                                model: {
                                  value: _vm.couponOptions.drawTimesType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "drawTimesType",
                                      $$v
                                    )
                                  },
                                  expression: "couponOptions.drawTimesType",
                                },
                              },
                              [_vm._v("每人活动期间最多领取")]
                            ),
                            [1].includes(_vm.couponOptions.drawTimesType)
                              ? _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "",
                                          prop: "everyOneTotalTimes",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "fillRuleinputMoney",
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              disabled: _vm.disableOperate,
                                              oninput:
                                                "value=value.replace(/^0|[^0-9]/g,'')",
                                            },
                                            model: {
                                              value:
                                                _vm.couponOptions
                                                  .everyOneTotalTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.couponOptions,
                                                  "everyOneTotalTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "couponOptions.everyOneTotalTimes",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "append" },
                                              [_c("el-button", [_vm._v("次")])],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("el-form-item", { attrs: { label: "" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  disabled: _vm.disableOperate,
                                  label: 2,
                                },
                                model: {
                                  value: _vm.couponOptions.drawTimesType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "drawTimesType",
                                      $$v
                                    )
                                  },
                                  expression: "couponOptions.drawTimesType",
                                },
                              },
                              [_vm._v("每人每天最多领取")]
                            ),
                            [2].includes(_vm.couponOptions.drawTimesType)
                              ? _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "",
                                          prop: "everyOneDayTimes",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticClass: "fillRuleinputMoney",
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              disabled: _vm.disableOperate,
                                              oninput:
                                                "value=value.replace(/^0|[^0-9]/g,'')",
                                            },
                                            model: {
                                              value:
                                                _vm.couponOptions
                                                  .everyOneDayTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.couponOptions,
                                                  "everyOneDayTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "couponOptions.everyOneDayTimes",
                                            },
                                          },
                                          [
                                            _c(
                                              "template",
                                              { slot: "append" },
                                              [_c("el-button", [_vm._v("次")])],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "拓展设置" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "giveRule" }, [
                  _c(
                    "div",
                    { staticClass: "multiplyTitle" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动分享卡文案" } },
                        [
                          _c(
                            "div",
                            { staticClass: "RuleListdetail" },
                            [
                              _c("el-input", {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  type: "textarea",
                                  placeholder: "请输入文案",
                                  maxlength: "200",
                                  autosize: { minRows: 1, maxRows: 1 },
                                  disabled: _vm.disableOperate,
                                },
                                model: {
                                  value:
                                    _vm.couponOptions
                                      .shareCardRecordActivitiesTitle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "shareCardRecordActivitiesTitle",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "couponOptions.shareCardRecordActivitiesTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              '不填写则展示默认文案"快来试试手气! 点下就能领到手, 速抢!"'
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动分享卡设置", required: true } },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 1,
                                  },
                                  model: {
                                    value: _vm.couponOptions.shareCardType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "shareCardType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.shareCardType",
                                  },
                                },
                                [_vm._v("默认分享卡")]
                              ),
                              _c("div", [
                                _c("img", {
                                  staticStyle: {
                                    "vertical-align": "text-top",
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: _vm.shareUrL, alt: "加载失败" },
                                }),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: _vm.disableOperate,
                                    label: 2,
                                  },
                                  model: {
                                    value: _vm.couponOptions.shareCardType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.couponOptions,
                                        "shareCardType",
                                        $$v
                                      )
                                    },
                                    expression: "couponOptions.shareCardType",
                                  },
                                },
                                [_vm._v("自定义分享卡")]
                              ),
                              _c("AccessoryUpload", {
                                attrs: {
                                  listType: "picture-card",
                                  size: "mini",
                                  title: "",
                                  limit: 1,
                                  fileList: _vm.fileList,
                                  dialogImageUrl:
                                    _vm.couponOptions.posterImageUrl,
                                  noneBtnImg:
                                    _vm.couponOptions.posterImageUrl != "",
                                  fileSize: 1,
                                },
                                on: {
                                  getFileItems: _vm.getFileItems,
                                  delFileItems: _vm.deleteImg,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { label: "退款设置:" } }, [
                        _c(
                          "div",
                          { staticClass: "RuleListdetail" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: _vm.disableOperate },
                                model: {
                                  value:
                                    _vm.couponOptions
                                      .isOrderRefundCouponInvalid,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.couponOptions,
                                      "isOrderRefundCouponInvalid",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "couponOptions.isOrderRefundCouponInvalid",
                                },
                              },
                              [_vm._v("订单退款时优惠券失效")]
                            ),
                            _c("span", { staticClass: "ml20 text" }, [
                              _vm._v(
                                "勾选后，若分享者的订单退款，所有通过该订单分享出来的优惠券将不可继续领取已领取未使用的优惠券也将失效。"
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c("Dialog", {
            attrs: { options: _vm.dialogOptions },
            on: {
              "update:options": function ($event) {
                _vm.dialogOptions = $event
              },
              handleEvent: _vm.handleEvent,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }