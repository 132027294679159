var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basicInfo" },
    [
      _c(
        "div",
        { staticClass: "x-f" },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "活动编号",
                prop:
                  (_vm.seckill ? "seckillBody" : "fightGroupBody") +
                  ".activityNo",
                "label-width": "120px",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: "", placeholder: "自动生成" },
                model: {
                  value: _vm.form.activityNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "activityNo", $$v)
                  },
                  expression: "form.activityNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "活动名称",
                prop:
                  (_vm.seckill ? "seckillBody" : "fightGroupBody") +
                  ".activityName",
                "label-width": "120px",
                rules: _vm.rules.activityName,
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: _vm.disabled },
                model: {
                  value: _vm.form.activityName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "activityName", $$v)
                  },
                  expression: "form.activityName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "x-f" },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "活动日期",
                "label-width": "120px",
                prop:
                  (_vm.seckill ? "seckillBody" : "fightGroupBody") +
                  ".activityTimes",
                rules: _vm.rules.activityTimes,
              },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  disabled: _vm.disabled,
                  "unlink-panels": "",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.form.activityTimes,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "activityTimes", $$v)
                  },
                  expression: "form.activityTimes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isBargain
        ? _c(
            "div",
            { staticClass: "bargain" },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "validity",
                  attrs: { label: "砍价有效期", "label-width": "120px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("el-form-item", [_c("el-input"), _vm._v(" 天 ")], 1),
                      _c("el-form-item", [_c("el-input"), _vm._v(" 时 ")], 1),
                      _c("el-form-item", [_c("el-input"), _vm._v(" 分 ")], 1),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tag" }, [
                    _vm._v(
                      " 砍价有效期需在15分钟至15天之间,若设置1天,用户发起砍价需要在24小时内邀请好友砍价成功,超时则砍价失败 "
                    ),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "validity",
                  attrs: { label: "砍价成功人数", "label-width": "120px" },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      disabled: _vm.disabled,
                      "controls-position": "right",
                      min: 1,
                    },
                    model: {
                      value: _vm.form.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "num", $$v)
                      },
                      expression: "form.num",
                    },
                  }),
                  _c("div", { staticClass: "tag" }, [
                    _vm._v(
                      " 砍价助力人数达到该人数后会通知用户砍价成功,请支付下单 "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "validity",
                  attrs: { label: "首刀砍价比例(%)", "label-width": "120px" },
                },
                [
                  _c("el-input"),
                  _c("div", { staticClass: "tag" }, [
                    _vm._v(
                      " 此处为买家发起砍价时自己首刀砍价比例,建议设的更高一点,提高买家砍价兴趣 "
                    ),
                  ]),
                  _c("div", { staticClass: "tag" }, [
                    _vm._v(
                      " 例: 商品售价为¥100,首刀比例为90%,则发起砍价时买家可以自己砍¥90 "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          staticClass: "payWay",
          attrs: {
            label: "支付方式",
            "label-width": "120px",
            prop:
              (_vm.seckill ? "seckillBody" : "fightGroupBody") +
              ".payChannelItems",
            rules: _vm.rules.payChannelItems,
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              attrs: { disabled: _vm.disabled },
              model: {
                value: _vm.form.payChannelItems,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "payChannelItems", $$v)
                },
                expression: "form.payChannelItems",
              },
            },
            [
              _c("el-checkbox", { attrs: { label: 0 } }, [_vm._v("微信支付")]),
              _c("el-checkbox", { attrs: { label: 1 } }, [
                _vm._v("会员卡支付"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "disWay",
          attrs: {
            label: "配送方式",
            "label-width": "120px",
            prop:
              (_vm.seckill ? "seckillBody" : "fightGroupBody") +
              ".fetchTypeItems",
            rules: _vm.rules.fetchTypeItems,
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              attrs: { disabled: _vm.disabled },
              model: {
                value: _vm.form.fetchTypeItems,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "fetchTypeItems", $$v)
                },
                expression: "form.fetchTypeItems",
              },
            },
            [
              _c("el-checkbox", { attrs: { label: 1 } }, [_vm._v("门店自提")]),
              _c("el-checkbox", { attrs: { label: 2 } }, [_vm._v("同城配送")]),
              _c("el-checkbox", { attrs: { label: 3 } }, [_vm._v("快递配送")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.form.deliveryType == 1000
        ? _c(
            "div",
            { staticClass: "selectDiyWay" },
            [
              _c(
                "el-form-item",
                { staticClass: "disWay", attrs: { "label-width": "120px" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.form.fetchTypeItems,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "fetchTypeItems", $$v)
                        },
                        expression: "form.fetchTypeItems",
                      },
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 1 } }, [
                        _vm._v("门店自提"),
                      ]),
                      _c("el-checkbox", { attrs: { label: 2 } }, [
                        _vm._v("同城配送"),
                      ]),
                      _c("el-checkbox", { attrs: { label: 3 } }, [
                        _vm._v("快递配送"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }